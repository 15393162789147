import React from 'react'

export default function Vision() {
  return (
    <>
    
    <section id="about" className="about">
        <div className="container">
          <div className="section-title">
            <h2>Our Mission & Strategy</h2>
          </div>
          {/* <div className="row"> */}
            {/* <div className="col-lg-6 order-1 order-lg-2">
              <img src={about} className="img-fluid" alt="test" />
            </div> */}
            {/* <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content"> */}
            <div className="col-lg-12 pt-6 pt-lg-0 order-2 order-lg-1 content" style={{ color: 'black', fontSize: '18px', fontFamily: 'Arial, sans-serif' }}>
              <h4 style={{ color: 'green', textAlign: 'center', fontSize: '25x', fontFamily: 'Arial, sans-serif' }}>Nutrition for All, Hunger for None</h4>
              <h4 style={{ color: 'red', fontFamily: 'Arial, sans-serif' }}>VISION:</h4>
              <p className="fst-italic">To encourage quality of life and healthy living of human being by delivering excellent crop management services to the farmers and to make our products best from the rest with leave a symbol of quality & satisfaction in the life of our trusted clients,
as a leading corporate in delivering agro-solutions and imparting knowledge to end user alongside strengthening manufacturing capabilities, strategic partnerships to superior technology and nurturing innovation across the valuable chain.</p>
              
              <h4 style={{ color: 'red', fontFamily: 'Arial, sans-serif' }}>Mission:</h4>
              <p className="fst-italic">Educate, Evolve and Enhance - Strategy of AGREEZ AGROCHEMICAL Pvt. Ltd.</p>
              
              
             </div>
          </div>
        {/* </div> */}
      </section>
    
    </>
  )
}
