import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "../assets/css/style.css";
import Home from "./Home";
import Footer from "./Footer";
import Navbar from "./Navbar";
import About from "./About";
import Contact from "./Contact";
import AllProducts from "./Product/AllProducts";
import Insecticide from "./Product/Insecticide";
import Herbicide from "./Product/Herbicide";
import Fungicide from "./Product/Fungicide";
import PGR from "./Product/PGR";
import FounderChairman from "./FounderChairman";
import QC from "./QC";
import Vision from "./Vision";
import ProductDetail from "./Product/ProductDetail";


export default function App() {
  return (
    <BrowserRouter> 
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/FounderChairman" element={<FounderChairman />} />
        <Route path="/QC" element={<QC />} />
        <Route path="/Vision" element={<Vision />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/AllProducts" element={<AllProducts />} />
        <Route path="/Insecticide" element={<Insecticide />} />
        <Route path="/Fungicide" element={<Fungicide />} />
        <Route path="/Herbicide" element={<Herbicide />} />
        <Route path="/PGR" element={<PGR />} />
        <Route path="/Insecticide/:title" element={<ProductDetail/>} />
        <Route path="/Herbicide/:title" element={<ProductDetail/>} />
        <Route path="/PGR/:title" element={<ProductDetail/>} />
        <Route path="/Fungicide/:title" element={<ProductDetail/>} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}
