import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper/modules";

import "../assets/css/slider.css";

const data1 = [
  {
    image: require("../assets/templates/c1.jpg"),
    // caption: "Caption 1",
    // description: "Description 1",
  },
  {
    image: require("../assets/templates/c2.jpg"),
    // caption: "Caption 2",
    // description: "Description 2",
  },
  // {
  //   image: require("../assets/templates/c3.jpg"),
  //   // caption: "Caption 3",
  //   // description: "Description 3",
  // },
  {
    image: require("../assets/templates/c4.jpg"),
    // caption: "Caption 4",
    // description: "Description 4",
  },
  {
    image: require("../assets/templates/c5.jpg"),
    // caption: "Caption 5",
    // description: "Description 5",
  },
  {
    image: require("../assets/templates/c6.jpg"),
    // caption: "Caption 6",
    // description: "Description 6",
  },
  {
    image: require("../assets/templates/c7.jpg"),
  },
  {
    image: require("../assets/templates/c8.jpg"),
  },
  {
    image: require("../assets/templates/c9.jpg"),
  },
  {
    image: require("../assets/templates/c10.jpg"),
  },
  {
    image: require("../assets/templates/c11.jpg"),
  },
  {
    image: require("../assets/templates/c12.jpg"),
  },
  {
    image: require("../assets/templates/c13.jpg"),
  },
  {
    image: require("../assets/templates/c14.jpg"),
  },
  {
    image: require("../assets/templates/c15.jpg"),
  },
  {
    image: require("../assets/templates/c16.jpg"),
  },
  {
    image: require("../assets/templates/c17.jpg"),
  },
  {
    image: require("../assets/templates/c18.jpg"),
  },
  {
    image: require("../assets/templates/c19.jpg"),
  },
  {
    image: require("../assets/templates/c20.jpg"),
  },
];

export default function SliderChain() {
  const swiperRef1 = useRef(null);

  const handleMouseEnter = (swiper) => {
    if (swiper && swiper.autoplay) {
      swiper.autoplay.stop();
    }
  };

  const handleMouseLeave = (swiper) => {
    if (swiper && swiper.autoplay) {
      swiper.autoplay.start();
    }
  };

  return (
    <div className="swiper-container">
      <Swiper
        ref={swiperRef1}
        modules={[Autoplay, Pagination, Navigation]}
        spaceBetween={30}
        slidesPerView={3}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        pagination={{ clickable: true }}
        navigation
        loop={true}
        breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
        }}
        onMouseEnter={() => handleMouseEnter(swiperRef1.current)}
        onMouseLeave={() => handleMouseLeave(swiperRef1.current)}
      >
        {data1.map((slide, i) => (
          <SwiperSlide key={i} className="swiper-slide">
            <img src={slide.image} alt={`Slide ${i + 1}`} />
            {/* <div className="carousel-caption">
              <h3>{slide.caption}</h3>
              <p>{slide.description}</p>
            </div> */}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
