import React from "react";
import about from "../assets/img/about.jpg";

export default function About() {
  return (
    <>
      <section id="about" className="about">
        <div className="container">
          <div className="section-title">
            <h2>About Us</h2>
            {/* <!-- <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p> --> */}
          </div>
          <div className="row">
            <div className="col-lg-6 order-1 order-lg-2">
              <img src={about} className="img-fluid" alt="test" />
            </div>
            <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content">
              <h4>
              AGREEZ AGROCHEMICAL PVT LTD was established in 2017 to provide the agro-solution to Indian farming community.
              </h4>
              <p className="fst-italic">
              We are committed to educate the farmer to use crop and pest specific crop protection products judiciously.
              </p>
              <ul>
                <li>
                  <i className="bi bi-check-circled"></i> We are working towards
                  adding new chemistry in the product basket to ensure safer
                  products for users.
                </li>
                <li>
                  <i className="bi bi-check-circled"></i> Our sales and
                  marketing team strategically guides the farmers with the
                  adequate and newly adopted techniques which range from
                  teaching sowing pattern, harvesting pattern and shifting from
                  one crop to another in reference to ongoing weather shifts.
                </li>
                <li>
                  <i className="bi bi-check-circled"></i> In the first phase, we
                  opened our operations in North India and are steadily shifting
                  towards Central India. In a little span of time, our
                  operations will be functional throughout the country.
                </li>
              </ul>
              <p>
              In the first phase, we opened our operations in states such as Punjab,Uttarakhand, Rajasthan and Madhya Pradesh we have a presence across 250 distributors and 3000 retailers. Our brand is sharing shelves with top leading brands among their categories. Our quality products and their attractive packaging is generating repeated purchase from the farmers which is further building their trust on our company and organisation as a whole.
We have a wide range of products from seed treatment to post harvesting solutions.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
