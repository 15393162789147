import React from "react";
import { Modal, Button, Card } from "react-bootstrap";
import { useParams } from "react-router-dom";
import DATA from "../Json/data.json";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../assets/css/product.css";

const SingleProduct = ({ isOpen, closeModal, productDesc }) => {


  // console.log("Decoded Title:", decodedTitle);
  console.log("Product Data:", productDesc);

  return (
    <Modal show={isOpen} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>{productDesc?.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card className="CardItem img-fluid text-center my-2 d-flex justify-content-between">
          <Card.Img className="CardItem-img" variant="top" src={productDesc?.image} />
          <Card.Body>
            <Card.Title>{productDesc?.title}</Card.Title>
            <Card.Subtitle className="mb-2 text-muted">{productDesc?.subtitle}</Card.Subtitle>
            <Card.Text>{productDesc?.description}</Card.Text>
          </Card.Body>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SingleProduct;
