import React, { useRef } from "react";
import emailjs from "@emailjs/browser";

import { Link } from "react-router-dom";

export default function Contact() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_y5qa763",
        "template_9b05u7m",
        form.current,
        "FEVeEOJXnEEut5gHv"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("message sent");
          e.target.reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <section id="contact" className="contact">
      <div className="container">
        <div className="section-title">
          <h2>Contact</h2>
          {/* <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p> */}
        </div>
        <div className="row ">
          <div className="col-lg-6 d-flex align-items-stretch ">
            <div className="info">
              <div className="address">
                <i className="bi bi-geo-alt"></i>
                <h4>Location:</h4>
                {/* <p>AnandNagar, Gwalior, M.P. 474012</p> */}
                <p>
                  BO-20,UPSIDC,GOPALPUR INDUSTRIAL AREA SIKANDRABAD (UP) 203205
                </p>
              </div>
              <div className="email">
                <i className="bi bi-envelope"></i>
                <h4>Email:</h4>
                <p>info@agreezagro.in</p>
              </div>
              <div className="phone">
                <i className="bi bi-phone"></i>
                <h4>Call:</h4>
                <p>+91 9111124400</p>
              </div>
              <div className="mapouter">
                <div className="gmap_canvas">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3506.587267343748!2d77.6451301754957!3d28.491973875741827!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjjCsDI5JzMxLjEiTiA3N8KwMzgnNTEuNyJF!5e0!3m2!1sen!2sin!4v1706447336358!5m2!1sen!2sin"
                    width="500"
                    height="450"
                    style={{ border: "0" }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                  <Link
                    to="https://123movies-to.org"
                    className="nav-link scrollto"
                  ></Link>
                  <Link
                    to="https://123movies-to.org"
                    className="nav-link scrollto"
                  ></Link>
                  {/* <style>.mapouter{position:relative;text-align:right;height:500px;width:600px;} </style> */}
                  <Link
                    to="https://www.embedgooglemap.net"
                    className="nav-link scrollto"
                  >
                    website google maps
                  </Link>

                  <style>
                    {`.gmap_canvas {overflow:hidden;background:none!important;height:700px;width:600px;}`}
                  </style>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 mt-5 mt-lg-0 d-flex align-items-stretch ">
            <form ref={form} onSubmit={sendEmail} className="php-email-form">
              <div className="row">
                <div className="form-group col-md-6">
                  <label htmlFor="name">Your Name</label>
                  <input
                    type="text"
                    name="user_name"
                    className="form-control"
                    id="name"
                    required
                  />
                </div>
                <div className="form-group col-md-6 mt-3 mt-md-0">
                  <label htmlFor="email">Your Email</label>
                  <input
                    type="email"
                    className="form-control"
                    name="user_email"
                    id="email"
                    required
                  />
                </div>
              </div>
              <div className="form-group mt-3">
                <label htmlFor="subject">Subject</label>
                <input
                  type="text"
                  className="form-control"
                  name="subject"
                  id="subject"
                  required
                />
              </div>
              <div className="form-group mt-3">
                <label htmlFor="message">Message</label>
                <textarea
                  className="form-control"
                  name="message"
                  rows="10"
                  required
                ></textarea>
              </div>
              <div className="text-center">
                <button
                  type="submit"
                  value="Send"
                  onClick={() =>
                    alert("Your message has been delivered successfully")
                  }
                >
                  Send Message
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}
