import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import DATA from "../Json/data.json";
import { Modal, Button, Table } from "react-bootstrap";

const ProductDetail = () => {
  const { title } = useParams();
  const decodedTitle = decodeURIComponent(title);
  const navigate = useNavigate(); // Use the useNavigate hook

  // Helper function to find the product in any category
  const findProduct = (title) => {
    for (let category in DATA) {
      if (Array.isArray(DATA[category])) {
        // Ensure the category is an array
        const product = DATA[category].find((item) => item.title === title);
        if (product) {
          return { product, category }; // Return both product and category
        }
      }
    }
    return null;
  };

  const result = findProduct(decodedTitle);
  const product = result?.product;
  const category = result?.category;

  const [show, setShow] = useState(true);

  // Close modal and navigate back to the category page
  const handleClose = () => {
    setShow(false);
    if (category) {
      navigate(`/${category}`); // Navigate to the category page
    }
  };

  console.log("Decoded Title:", decodedTitle);
  console.log("Product Data:", product);

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{product?.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {product ? (
          <Table bordered hover>
            <tbody>
              <tr>
                <td colSpan="2" className="text-center">
                  <img
                    className="img-fluid mx-auto d-block"
                    src={`/${product.image}`}
                    alt={product.title}
                    style={{ maxWidth: "200px", maxHeight: "200px" }}
                  />
                </td>
              </tr>
              <tr>
                <td>Product Name</td>
                <td>{product.title}</td>
              </tr>
              <tr>
                <td>Subtitle</td>
                <td>{product.subTitle}</td>
              </tr>
              <tr>
                <td>Description</td>
                <td>{product.description}</td>
              </tr>
              <tr>
                <td>MFG LIC. NO</td>
                <td>422/UP/BSR</td>
              </tr>
              <tr>
                <td>MFG & MKTD BY</td>
                <td>AGREEZ AGROCHEMICAL PVT. LTD.</td>
              </tr>
              <tr>
                <td>FACTORY</td>
                <td>
                  BO-20 UPSIDC GOPALPUR IND. AREA SIKANDRABAD (U.P.) 203205
                </td>
              </tr>
              <tr>
                <td>E-MAIL</td>
                <td>info@agreezagro.in</td>
              </tr>
              <tr>
                <td>CUSTOMER CARE NUMBER</td>
                <td>+91 9111124400</td>
              </tr>
              <tr>
                <td>CERTIFICATION</td>
                <td>AN ISO 9001:2015 & 14001:2015 CERTIFIED COMPANY</td>
              </tr>
            </tbody>
          </Table>
        ) : (
          <p>Product not found</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ProductDetail;
