import React from "react";
import "../assets/css/style.css";
import About from "./About";
import Slider from "./Slider";
import Contact from "./Contact";
import Vision from "./Vision";
import QC from "./QC";

export default function Home() {
  return (
    <>
      <div
        className="carousel-inner"
        role="listbox"
        style={{ height: "600px", objectFit: "cover" }}
      >
        <Slider />
      </div>
      {/* <Product /> */}
      <About />
      <Vision />
      {/* <QC /> */}
      <Contact />
    </>
  );
}
