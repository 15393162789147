import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../assets/css/product.css";
import CardProduct from "./CardProduct";
import DATA from "../Json/data.json";
import { Container, Row, Col } from "react-bootstrap";
import SingleProduct from "./SingleProduct";
import { useNavigate } from "react-router-dom";

export default function PGR() {
  const navigate = useNavigate();

  const handleCardClick = (product_details) => {
    navigate(`/PGR/${encodeURIComponent(product_details.title)}`);
  };

  const [productDesc, setProductDesc] = useState(null);
  const [productModal, setProductModal] = useState(false);

  const handleClick = (product_details) => {
    setProductModal(true);
    setProductDesc(product_details);
  };

  const closeModal = () => {
    setProductModal(false);
    setProductDesc(null);
  };

  return (
    <>
      <section id="product" className="product">
        <div className="">
          <div className="section-title">
            <h2 className="text-center my-4">PGR</h2>
          </div>
          <Container fluid>
            <Row className="d-flex justify-content-center p-5">
              {DATA.PGR.map((item, index) => (
                <Col xs={12} sm={6} md={4} lg={2} key={index} className="mb-0">
                  <CardProduct item={item} handleClick={handleClick} />
                </Col>
              ))}
            </Row>
          </Container>
        </div>
      </section>
      {productModal && (
        <SingleProduct
          isOpen={productModal}
          closeModal={closeModal}
          productDesc={productDesc}
        />
      )}
    </>
  );
}
