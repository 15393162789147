import React from "react";
import about from "../assets/img/about.jpg";

export default function QC() {
  return (
    <>
      <section id="about" className="about">
        <div className="container">
          <div className="section-title">
            <h2>Quality Control Policy</h2>
            {/* <!-- <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p> --> */}
          </div>
          <div className="row">
            <div className="col-lg-6 order-1 order-lg-2">
              <img src={about} className="img-fluid" alt="test" />
            </div>
            <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content">
              <h3>
                Agreez Agrochemical Pvt. Ltd.follows stringent quality policy at
                each level from raw material purchase to finished packed
                product.
              </h3>
              <p className="fst-italic">
                At the beginning the quality of raw material is checked in our
                lab to ensure the quality of purchased material and sample of
                each batch number is checked and procured at lab for our future
                reference.
              </p>
              <p className="fst-italic">
                We are certified under the industry-wide recognized quality
                standards published by the International Organization of
                Standardization (ISO). The certifications include:
                <br /> 1. ISO 9001:2015 <br /> 2. ISO 14001:2015
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
