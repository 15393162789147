import React from "react";
import { Button, Card } from "react-bootstrap";
import "../../assets/css/product.css";

export default function CardProduct({ item, handleClick }) {
  return (
    <Card className="CardItem img-fluid text-center my-2 d-flex justify-content-between">
      <Card.Img className="CardItem-img" variant="top" src={item?.image} />
      <Card.Body>
        <Card.Title>{item?.title}</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">{item?.subtitle}</Card.Subtitle>
        <Card.Text>{item?.description}</Card.Text>
        <Button variant="secondary" onClick={() => handleClick(item)}>
          View Product
        </Button>
      </Card.Body>
    </Card>
  );
}
