import React from "react";
import "../assets/css/style.css";

export default function Footer() {
  return (
    <>
      <footer id="footer" className="footer">
        <div className="container">
          <h3>Agreez-Agro</h3>

          <div className="container text-center">
            <a href="https://www.facebook.com/profile.php?id=100094450271928&mibextid=LQQJ4d">
              <i className="fa fa-facebook"></i>
            </a>
            <a href="#">
              <i className="fa fa-twitter"></i>
            </a>
            <a href="#">
              <i className="fa fa-linkedin"></i>
            </a>
            <a href="#">
              <i className="fa fa-google-plus"></i>
            </a>
            <a href="#">
              <i className="fa fa-skype"></i>
            </a>
          </div>
          <br />
          <div className="copyright">
            &copy; Copyright{" "}
            <strong>
              <span>AgreezAgro</span>
            </strong>
            . All Rights Reserved
          </div>
          <div className="credits">
            {/* <!-- All the links in the footer should remain intact. -->
          <!-- You can delete the links only if you purchased the pro version. -->
          <!-- Licensing information: https://bootstrapmade.com/license/ -->
          <!-- Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/green-free-one-page-bootstrap-template/ */}
            Designed by <a href="https://www.agreezagro.in">AgreezAgro </a>
          </div>
        </div>
      </footer>
    </>
  );
}
